<template>
  <div v-if="$store.state.st_isDbConnect">
    <router-view />
  </div>
  <div v-else class="container db-disconnect">
    <div class="uk-overlay-primary uk-position-cover common-font">
      <div class="uk-position-center"></div>
    </div>
    <div
      class="uk-container uk-position-center uk-flex uk-flex-middle uk-padding-large common-font"
    >
      <div class="uk-flex-center uk-flex-middle">
        <div class="uk-card uk-card-secondary uk-border-rounded">
          <div
            class="uk-card-header"
            style="border-bottom: 1px solid whitesmoke"
          >
            <div class="uk-flex uk-flex-center uk-flex-middle">
              <img
                class="uk-border-circle"
                width="40"
                height="40"
                src="@/assets/holonics.png"
                alt="Avatar"
              />
              <div
                class="uk-card-title uk-margin-remove-bottom uk-text-secondary common-font uk-margin-small-left"
              >
                面会WEB予約
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-text-large:m uk-text-default@s">
            <div class="uk-flex uk-flex-center uk-flex-middle uk-margin">
              <span
                id="maintenance-icon"
                uk-icon="icon: database"
                ratio="3"
                style="color: #666666"
              ></span>
            </div>
            <div class="uk-text-center">
              <div>メンテナンス中・・</div>
            </div>
            <div class="uk-text-center uk-margin-top">
              <div>時間をおき</div>
              <div>ページを再読込してください</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 処理中クルクル画面 -->
  <!-- uikitのモーダルのz-indexが1010のため、それより大きい数値を指定する -->
  <div
    v-show="$store.state.st_isServerProcessing"
    style="
      z-index: 1100;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    "
  >
    <div class="uk-overlay-primary uk-position-cover common-font">
      <div class="uk-position-center">
        <div uk-spinner="ratio:5"></div>
        <div>
          <div class="uk-text-center">処理中です</div>
        </div>
      </div>
    </div>
  </div>
  <!-- エラーcatch -->
  <div
    v-show="$store.state.st_isCatchError"
    style="
      z-index: 1100;
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
    "
  >
    <div class="uk-overlay-primary uk-position-cover common-font">
      <div class="uk-position-center"></div>
    </div>
    <div
      class="uk-container uk-position-center uk-flex uk-flex-middle uk-padding-large common-font"
    >
      <div class="uk-flex-center uk-flex-middle">
        <div class="uk-card uk-card-default uk-border-rounded">
          <div class="uk-card-header">
            <div class="uk-flex uk-flex-center uk-flex-middle">
              <img
                class="uk-border-circle"
                width="40"
                height="40"
                src="@/assets/holonics.png"
                alt="Avatar"
              />
              <div
                class="uk-card-title uk-margin-remove-bottom uk-text-secondary common-font uk-margin-small-left"
              >
                面会WEB予約
              </div>
            </div>
          </div>
          <div class="uk-card-body uk-text-large:m uk-text-default@s">
            <div class="uk-flex uk-flex-center uk-flex-middle uk-margin">
              <span
                id="maintenance-icon"
                uk-icon="icon: cog"
                ratio="3"
                style="color: #666666"
              ></span>
            </div>
            <div class="uk-text-center">
              <div>メンテナンス中・・</div>
            </div>
            <div class="uk-text-center uk-margin-top">
              <div>時間をおき</div>
              <div>ページを再読込してください</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {
    this.$axios.get("/").then(
      (res) => {
        /* eslint-disable no-unused-vars */
        res;
        /* eslint-enable no-unused-vars */
        this.$store.state.st_isDbConnect = true;
      },
      (err) => {
        /* eslint-disable no-unused-vars */
        err;
        /* eslint-enable no-unused-vars */
        this.$store.state.st_isDbConnect = false;
      }
    );
  },
};
</script>

<style scope>
.db-disconnect {
  opacity: 0;

  /* アニメーション */
  transition-duration: 1500ms;
  animation: fadeIn 3s linear;
  animation-fill-mode: both;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#maintenance-icon {
  animation: 5s linear infinite rotation;
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
