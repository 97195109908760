// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    redirect: "/hp-kokusai",
    name: "entrance",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-kokusai",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-kojima",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-settsu",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-ibaraki",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-kanzaki",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-kashihara",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-toushun",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/hp-higashimaiduru",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/EntranceView.vue"),
  },
  {
    path: "/schedule",
    name: "schedule",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/ScheduleView.vue"),
  },
  {
    path: "/input-info",
    name: "inputinfo",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/InputInfoView.vue"),
  },
  {
    path: "/confirm-input",
    name: "confirminput",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/usr/ConfirmInputView.vue"
      ),
  },
  {
    path: "/finish-reservation",
    name: "finishreservation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/usr/FinishReservationView.vue"
      ),
  },
  {
    path: "/my-reservation",
    name: "myreservation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/usr/MyReservationView.vue"
      ),
  },
  {
    path: "/check-in",
    name: "checkin",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/usr/CheckInView.vue"),
  },
  {
    path: "/next-reservation",
    name: "nextreservation",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/usr/NextReservationView.vue"
      ),
  },
  {
    path: "/error",
    name: "InternalServerError",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ErrorDisplay.vue"),
  },
  {
    path: "/:catchAll(.*)",
    components: {
      default: () => import("../views/NotFound.vue"),
    },
  },
];

const router = createRouter({
  // history: createWebHashHistory(),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    /* eslint-disable no-unused-vars */
    to;
    from;
    savedPosition;
    /* eslint-enable no-unused-vars */
    return { x: 0, y: 0 };
  },
});

export default router;
