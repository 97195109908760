import "./styles/summarize_style.scss";

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "uikit/dist/css/uikit.css";
import "uikit/dist/css/uikit.min.css";
import { _hensuu } from "./datas/data";
UIkit.use(Icons);

const vueObj = createApp(App);
vueObj.config.globalProperties.$axios = axios.create({
  baseURL: _hensuu.axiosUrl,
});
vueObj.config.errorHandler = (err, vm, info) => {
  console.error(`***menkai-yoyaku-errorHandler: ${info}`, err);
  router.replace({ name: "InternalServerError" });
};
vueObj.use(store);
vueObj.use(router);
vueObj.mount("#app");
