// カレンダーの月エリアリスト
export const _tmpCalendarMonth = [
  { year: "", month: "", date: "日", able: true },
  { year: "", month: "", date: "月", able: true },
  { year: "", month: "", date: "火", able: true },
  { year: "", month: "", date: "水", able: true },
  { year: "", month: "", date: "木", able: true },
  { year: "", month: "", date: "金", able: true },
  { year: "", month: "", date: "土", able: true },
];

// いろんな変数をまとめておく
export const _hensuu = {
  maxLengthPatientName: 50,
  maxLengthVisitorName: 25,
  maxLengthVisitorTel: 20,
  maxLengthVisitorMail: 100,
  maxLengthVisitorRelationInput: 20,
  relationCodeFamily: "REL01",
  relationCodeOther: "REL02",
  relationCodeFamilySonota: "REL01-99",
  relationCodeOtherSonota: "REL02-99",
  selectAnswerUnknownText: "（不明）",
  selectAnswerUnknownValue: -99,
  reservationStatus: {
    temporary: 0,
    confirmed: 1,
    checkedin: 2,
    visited: 3,
    canceled: 9,
  },
  // // テスト
  // baseUrl: "http://localhost:8081/",
  // axiosUrl: "http://127.0.0.1:8080/",

  // 本番
  // baseUrl: "https://menkai-web-ks7nle7iyq-dt.a.run.app/",
  baseUrl: "https://menkai-yoyaku.holonicsystem.cloud",
  axiosUrl: "https://menkai-app-ks7nle7iyq-dt.a.run.app/",
};
